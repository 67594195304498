import axios from "axios";
import GetBaseUrl from "./GetBaseUrl";

const FetchData = async (Url, token = null, locale = null) => {
  const base = GetBaseUrl();
  const endpoint = base.endsWith('/') ? base.slice(0, -1) + Url : base + Url;

  try {
    const response = await axios.get(endpoint, {
      headers: {
        'Accept-Language': locale,
         Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    console.log('response',response.status)
    return {data:response.data,status:response.status};
  } catch (err) {
    console.log('response',err.response.status)
    return err.response?{data:err.response.data,status:err.response.status}:{data:err.response.data,status:'430'};
  }
};

export default FetchData;

